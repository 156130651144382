














































































































import {
    Component,
    Vue,
    Watch,
} from 'vue-property-decorator';
import {
    Core
} from '@/store/core'
import {
    Auth
} from '@/store/auth'
import {
    User
} from '@/store/user'

import {
    City
} from "@/store/city";
import MapView from '@/components/Maps/MapView.vue';

import moment from "moment";
import { FarmForm } from "@/models/farm";
import { Map } from "@/store/map";
import { App } from "@/store/app"; 

@Component({
    components: { MapView },
    computed: {},
    props: {
        color: {
            default: "light",
            validator: function (value) {
                // The value must match one of these strings
                return ["light", "dark"].indexOf(value) !== -1;
            },
        },
    }
})

export default class Farm extends Vue {
    private user: any = {}
    private farm: FarmForm | any = {}
    private buffalos: any = []
    private response: boolean = false 
    private page: number = 1
    private allPages: number = 1
    private search: string = ''
    private search2: string = ''

    private async created() {
        await this.run();
        // await this.loadProvinces();
        // await this.loadProducts()
    }

    // async loadProducts() {
    //     let search = this.$route.query.search
    //     search = (search) ? `search=${search}` : ''
    //     this.buffalos = await Core.getHttp(`/api/buffalo/all/?search=${this.search}${this.province}`)
    // }

    private async run() {
        await App.onLoad()
        let user = await User.getUser();
        this.user = await Core.getHttp(`/api/account/${user.pk}/`)
        this.farm = await Core.getHttp(`/user/buffalo/farm/${user.pk}/`)
        this.buffalos = await Core.getHttp(`/api/buffalo/all/?search=${this.search}`)
        this.allPages = Math.ceil((this.buffalos ?.count / 12))
        this.response = true
        await App.offLoad();
    }

    // provinces: any = null
    // province: any = '';
    // async loadProvinces() {
    //     this.provinces = await Core.getHttp(`/api/province/`)
    // }

    private async run2() {
        await App.onLoad()
        let user = await User.getUser();
        this.user = await Core.getHttp(`/api/account/${user.pk}/`)
        this.farm = await Core.getHttp(`/user/buffalo/farm/${user.pk}/`)
        this.buffalos = await Core.getHttp(`/api/buffalo/all/?search=${this.search2}`)
        this.allPages = Math.ceil((this.buffalos ?.count / 12))
        this.response = true
        await App.offLoad();
    }

    private async handlePageChange(value: any) {
        await App.onLoad()
        this.buffalos = await Core.getHttp(`/api/buffalo/all/?page=${value}&search=${this.search}`)
        await App.offLoad();
    }
 
}
