<template>
<div class="p-2 pt-6">
     <Card />
</div>
</template>

<script>
import Card from "@/components/Component/User/Search/Card.vue";
export default {
  name: "search-page",
  components: {
    Card,
  },
};
</script>
